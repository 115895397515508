<template>
  <div class="login-wrapper">
    <div class="login-img" />
    <div class="box-img">
      <img src="../../assets/images/box/logo.png" alt="" />
    </div>
    <div class="login-logo">
      <img src="../../assets/images/box/logo-img.png" alt="" />
    </div>

    <div class="login-form">
      <div class="switch-btn" @click="accountLogin()">返回账号登录</div>

      <div class="tabTitle">
        <div class="codeLogin" v-if="loginType == 'code'">安全验证</div>
        <div class="codeLogin" v-else>设置密码和输入邀请码</div>
      </div>
      <a-form
        v-if="loginType == 'code'"
        ref="forgetForm"
        :model="form.loginCode"
        :wrapper-col="{ span: 24 }"
        :validate-trigger="['change', 'blur']"
        :colon="false"
        hide-required-mark
      >
        <a-form-item v-bind="validateInfosCode.validateInfos.mobile">
          <a-input
            allow-clear
            autocomplete="off"
            v-model:value="form.loginCode.mobile"
            :placeholder="$t('login.account.placeholder')"
          />
        </a-form-item>
        <a-form-item v-bind="validateInfosCode.validateInfos.verifyCode">
          <a-input
            autocomplete="off"
            v-model:value="form.loginCode.verifyCode"
            :placeholder="$t('login.code')"
          />
          <a-button
            class="code-img"
            type="primary"
            :disabled="btn.disabled"
            ghost
            @click="handleGetCode"
          >
            {{ btn.title }}
          </a-button>
        </a-form-item>
      </a-form>

      <!--    注册账号   -->
      <a-form
        v-else
        ref="newAccountForm"
        :model="form.newAccount"
        :wrapper-col="{ span: 24 }"
        :validate-trigger="['change', 'blur']"
        :colon="false"
        hide-required-mark
      >
        <!-- <a-form-item v-bind="validateInfosNewAccount.validateInfos.userName">
          <a-input
            allow-clear
            autocomplete="off"
            v-model:value="form.newAccount.userName"
            placeholder="昵称"
          />
        </a-form-item> -->
        <a-form-item v-bind="validateInfosNewAccount.validateInfos.password">
          <a-input-password
            v-model:value="form.newAccount.password"
            placeholder="请输入密码"
            @keyup.enter="handleLogin"
            autocomplete="new-password"
          />
        </a-form-item>
        <!--      邀请码   -->
        <a-form-item v-bind="validateInfosNewAccount.validateInfos.inviterCode">
          <a-input
            allow-clear
            autocomplete="off"
            v-model:value="form.newAccount.inviterCode"
            placeholder="请输入邀请码"
          />
        </a-form-item>
      </a-form>

      <div
        class="agreement"
        :class="{ agreementActivity: loginType === 'newAccount' }"
      >
        <a-checkbox v-model:checked="checked"> </a-checkbox>
        <div class="ml-8">
          已阅读并同意<a>《使用协议》</a>和
          <a>《隐私保护协议》</a>
        </div>
      </div>
      <a-button class="btn" :loading="loading" block @click="handleLogin()">
        {{ loginText }}
      </a-button>
    </div>

    <div class="copyright cursor-p">
      <div @click="onloadApp" class="cursor-p downLoad">
        <img
          src="@/assets/images/menu/download.png"
          style="width: 14px; margin-right: 8px"
          alt=""
        />
        下载人脉宝盒APP
      </div>
      <div @click="gotoCopyright">
        {{ $t('login.copyright') }}
      </div>
    </div>
    <div class="iss-logo">
      <img src="../../assets/images/login-logo.png" alt="" />
    </div>
  </div>
</template>

<script>
import {
  ref,
  onMounted,
  reactive,
  toRefs,
  toRaw,
  watch,
  onUnmounted,
} from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { Checkbox, Alert, Form, message } from 'ant-design-vue';
import { randoms } from '@/utils';
import { validMobile, validEmail } from '@/utils/validation';
import errorImg from '@/assets/images/error.png';
import loginApi from '@/api/login.js';
import todayApi from '@/api/today';
import dashboardApi from '@/api/dashboard';

export default {
  components: {
    AForm: Form,
    AFormItem: Form.Item,
    // AAlert: Alert,
    ACheckbox: Checkbox,
    // AImage: Image,
    // ASpin: Spin,
  },

  setup() {
    const forgetForm = ref();
    const loginForm = ref();
    const passwordForm = ref();
    const newAccountForm = ref();
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const visibilityToggle = ref(true);
    const required = { required: true, whitespace: true, message: '不能为空' };
    const fromType = ref(true);
    const expireTimer = ref(null);
    const isExpire = ref(false);
    const wxCode = ref('');

    const state = reactive({
      spinning: false,
      loading: false,
      codeSrc: '',
      loginType: 'code',
      loginText: '登录',
      show: 'login',
      checked: false,
      // 根据字段来调取获取验证码接口
      validataCode: '',
      codeParmas: '',
      clearTimer: '',

      // 密码校验
      passwordForm: { oldPassword: '', password: '', confirmPassword: '' },
      passwordRules: {
        oldPassword: required,
        password: [
          required,
          {
            pattern:
              /^(?![A-z0-9]+$)(?=.[^%&',;=?$\x22])(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,20}$/,
            message: '长度为8-20位，必须同时含有字母(大小写)、数字和符号',
          },
        ],
        confirmPassword: {
          validator: (rule, value) => {
            return value.trim()
              ? value === state.passwordForm.password
                ? Promise.resolve()
                : Promise.reject('两次输入密码不匹配')
              : Promise.reject('不能为空');
          },
          trigger: 'blur',
        },
      },
      btn: {
        disabled: false,
        title: '获取验证码',
      },
      cache: {},
    });

    const form = reactive({
      //  验证码登录loginCode
      loginCode: { mobile: '', verifyCode: '', areaCode: '86' },
      //  注册账号
      newAccount: {
        userName: '',
        mobile: '',
        verifyCode: '',
        password: '',
        areaCode: '86',
        inviterCode: route.query.inviterCode ? route.query.inviterCode : '',
      },
    });
    //    validate,
    // 验证码登录验证
    const validateInfosCode = Form.useForm(form.loginCode, {
      verifyCode: [required],
      mobile: [
        {
          validator: async (rule, mobile) => {
            if (mobile) {
              // || validEmail(mobile)
              if (validMobile(mobile)) {
                return Promise.resolve();
              }
              return Promise.reject('请输入合法的手机号');
            }
            return Promise.reject('不能为空');
          },
        },
      ],
    });

    //注册验证
    const validateInfosNewAccount = Form.useForm(form.newAccount, {
      // verifyCode: [required],
      // mobile: [],
      password: [
        required,
        {
          pattern: /^(?![A-z0-9]+$)(?=.[^%&',;=?$\x22])(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,20}$/,
          message: '请输入正确的密码格式',
        },
      ],
      // userName: [required],
      // inviterCode:
    });

    const toResigter = () => {
      fromType.value = true;
      state.loginType = 'newAccount';
    };

    const getBehaviorPage = () => {
      //  获取用户导入行为页面信息
      dashboardApi
        .getBehaviorPage({
          userId: store.state.account.user.id,
          importSituation: 0,
        })
        .then(res => {
          // 有数据就去我设置的页面，否去dashboard页面
          if (res) {
            // console.log('shuju shuju', res, typeof res);
            // console.log('13', res);
            router.replace({
              path: res,
              query: {
                importStatus: 0,
              },
            });
          } else {
            // console.log('14', res);
            router.replace({
              path: '/dashboard',
              query: {
                importStatus: 0,
              },
            });
          }
        });
    };

    const getCaptcha = () => {
      state.spinning = true;
      form.loginForm.key = randoms(24, 16);
      loginApi
        .getCaptcha(form.loginForm.key)
        .then(data => (state.codeSrc = data))
        .catch(err => {
          console.log(err);
          getCaptcha();
        })
        .finally(() => (state.spinning = false));
    };

    const getImportSituation = () => {
      // console.log('id', store.state.account.user.id);
      todayApi
        .getContactImportSituation('', {
          userId: store.state.account.user.id,
        })
        .then(res => {
          if (res) {
            // "Novice"新手 "incomplete"未完成    "untreated" 未处理  “complete”完成
            if (res === 'untreated') {
              router.replace('/today');
            } else if (res === 'Novice') {
              router.replace('/welcome');
            } else if (res === 'incomplete') {
              // debugger;
              getBehaviorPage();
            } else if (res === 'complete') {
              router.replace('/today');
            } else {
              debugger;
            }
          }
        });
    };

    onMounted(() => {
      if (!localStorage.getItem('code')) {
        router.replace('/login');
        return;
      }

      wxCode.value = localStorage.getItem('code');
    });

    onUnmounted(() => {
      localStorage.removeItem('code');
    });

    watch(
      () => state.clearTimer,
      value => {
        console.log('watch', value);
        clearInterval(state.clearTimer);
      },
      { deep: true }
    );

    return {
      ...toRefs(state),
      getImportSituation,
      form,
      fromType,
      getCaptcha,
      visibilityToggle,
      forgetForm,
      loginForm,
      passwordForm,
      newAccountForm,
      required,
      validateInfosCode,
      validateInfosNewAccount,
      toResigter,
      expireTimer,
      isExpire,
      wxCode,
      logoUrl: process.env.VUE_APP_PROJECT_LOGIN_LOGO,
      errorImg,
      type: {
        loginType: '',
      },
      btnText: {
        login: '立即登录',
        forget: '下一步',
        forgetPassword: '完成',
        password: '完成',
        reLogin: '重新登录',
      },
      linkText: {
        login: '忘记密码',
        forget: '登录',
        forgetPassword: '登录',
        signAccount: '注册账号',
      },
      accountLogin() {
        router.replace({
          path: '/login',
        });
      },
      gotoCopyright() {
        window.open('https://beian.miit.gov.cn/#/Integrated/index', '_blank');
      },
      onloadApp() {
        window.open(
          'https://ismartek-app-uat.issmart.com.cn/apk/ctb/test/v1.2.0-test.apk',
          '_self'
        );
      },
      // todo 登录方法
      handleLogin() {
        if (state.loginType === 'code') {
          validateInfosCode.validate().then(() => {
            if (!state.checked) {
              return message.error('请勾选协议！');
            }

            state.loading = true;
            loginApi
              .qrcodeBindPhone('', {
                areaCode: form.loginCode.areaCode,
                mobile: form.loginCode.mobile,
                verifyCode: form.loginCode.verifyCode,
                code: wxCode.value,
                createTime: localStorage.getItem('ctime'),
                channelSource: '官网',
              })
              .then(res => {
                if (res.userStatus == 'alreadyBingMobile') {
                  message.success('账户绑定成功');
                  localStorage.removeItem('code');
                  localStorage.removeItem('ctime');
                  store.commit('account/setToken', res.token);
                  store.commit('account/setUser', res);
                  getImportSituation();
                  state.checked = false;
                  validateInfosCode.resetFields();
                }
                if (res.userStatus == 'unBingMobile') {
                  message.success('账户绑定成功，请补充账户信息');
                  localStorage.removeItem('code');
                  localStorage.removeItem('ctime');
                  store.commit('account/setToken', res.token);
                  store.commit('account/setUser', res);
                  toResigter();
                }
              })
              .catch(() => {
                form.loginForm.verifyCode = '';
              })
              .finally(() => {
                setTimeout(() => {
                  state.loading = false;
                }, 1500);
              });
          });
        }

        if (state.loginType === 'newAccount') {
          validateInfosNewAccount.validate().then(() => {
            if (!state.checked) {
              return message.error('请勾选协议！');
            }
            state.loading = true;
            loginApi
              .qrcodeBindMessage('', {
                id: store.state.account.user.id,
                password: form.newAccount.password,
                inviterCode: form.newAccount.inviterCode,
              })
              .then(res => {
                if (res) {
                  message.success('账户信息修改成功');
                  getImportSituation();
                  state.checked = false;
                  validateInfosNewAccount.resetFields();
                }
              })
              .catch(() => {
                form.loginForm.verifyCode = '';
                // getCaptcha();
              })
              .finally(() => {
                setTimeout(() => {
                  state.loading = false;
                }, 1500);
              });
          });
        }
      },

      handleGetCode() {
        if (state.loginType === 'newAccount') {
          state.validataCode = validateInfosNewAccount;
          state.codeParmas = form.newAccount.mobile;
        } else if (state.loginType === 'code') {
          state.validataCode = validateInfosCode;
          state.codeParmas = form.loginCode.mobile;
        }

        state.validataCode?.validate('mobile').then(() => {
          loginApi.getCode(state.codeParmas).then(res => {
            message.success('验证码已发送，请注意查收');
            if (res) {
              // store.commit('account/setTenant', data);
              state.btn = { disabled: true, title: '重新获取(60s)' };
              let count = 60;
              state.clearTimer = setInterval(() => {
                if (count === 1) {
                  state.btn = { disabled: false, title: '获取验证码' };
                  clearInterval(state.clearTimer);
                  state.clearTimer = null;
                } else {
                  count--;
                  state.btn.title = `重新获取(${count}s)`;
                }
              }, 1000);
            }
          });
        });
      },
    };
  },
};
</script>

<style lang="less" scoped>
.login-wrapper {
  position: relative;
  display: flex;
  height: 100vh;
  width: 100%;
  //.login-logo {
  //  flex: 1;
  //  background: linear-gradient(90deg, #7e98ff 0%, #5979f8 100%);
  //  position: relative;
  //}
  .copyright {
    color: #1d2129;
    position: absolute;
    right: 50%;
    transform: translateX(50%);
    bottom: 20px;
  }
  .iss-logo {
    width: 12%;
    position: absolute;
    left: 5%;
    top: 5%;
    img {
      width: 100%;
    }
  }
  .box-img {
    position: absolute;
    top: 52px;
    left: 52px;
  }
  .login-img {
    min-width: 960px;
    flex: 3;
    background: url('../../assets/images/box/back-img.png') no-repeat center;
    background-size: 100% 100%;
    background-position: right;
  }
  .login-form {
    min-width: 320px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 12%;
    max-width: 80%;
    width: 380px;
    //height: 500px;
    height: 560px;
    padding: 54px 40px 40px;
    background-color: #ffffffcc;
    backdrop-filter: blur(10px);
    box-shadow: 0px 2px 32px 0px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    border: 2px solid #ffffff;
    box-sizing: border-box;
    :deep(.ant-form label) {
      font-size: 16px;
    }

    .type-switch {
      width: 64px;
      height: 64px;
      object-fit: cover;
      position: absolute;
      top: -2px;
      right: -2px;
      cursor: pointer;
    }

    .switch-btn {
      padding: 5px 12px;
      box-sizing: border-box;
      background: #ffece5;
      border-radius: 5px;
      position: absolute;
      top: 20px;
      right: 70px;
      color: #ff7b00;

      span {
        color: #ff7b00;
        font-size: 14px;
        font-weight: 400;
      }
    }

    .form-title {
      color: #333333;
      font-size: 24px;
      font-weight: 500;
    }

    .code-contain {
      width: 192px;
      height: 192px;
      margin: 66px auto 0;
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      background: url('../../assets/images/login/code-contain-back.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      position: relative;

      .expire-mark {
        width: 180px;
        height: 180px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: rgba(0, 0, 0, 0.6);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 3;
        gap: 22px;

        .expire-tip {
          color: #ffffff;
          font-size: 18px;
          font-weight: 400;
        }

        .expire-btn {
          padding: 8px 32px;
          box-sizing: border-box;
          border: 1px solid #ffffff;
          border-radius: 5px;
          cursor: pointer;

          span {
            color: #ffffff;
            font-size: 16px;
            font-weight: 400;
          }
        }
      }

      #codeArea {
        width: 160px;
        height: 160px;
      }
    }

    .form-tips {
      width: 100%;
      margin-top: 54px;
      display: inline-block;
      color: #999999;
      font-size: 16px;
      font-weight: 400;
      text-align: center;
    }

    .register-link {
      width: 100%;
      margin-top: 28px;
      display: inline-block;
      color: #ff7b00;
      font-size: 16px;
      font-weight: 400;
      text-align: center;
      cursor: pointer;
    }
    //.title {
    //  font-weight: bold;
    //  margin-bottom: 2vw;
    //  font-size: 2rem;
    //  img {
    //    width: 60%;
    //    height: auto;
    //  }
    //}
    :deep(.ant-form-item) {
      //margin-bottom: 16px;
      .anticon-eye,
      .anticon-eye-invisible {
        color: rgba(0, 0, 0, 0.25);
      }
      &.ant-form-item-with-help {
        margin-bottom: 0;
      }
    }
    :deep(.ant-form-item-label) {
      text-align-last: justify;
      text-align: justify;
      text-justify: distribute-all-lines; // 兼容 IE
    }
    :deep(.ant-input),
    :deep(.ant-input-affix-wrapper),
    :deep(.ant-input-affix-wrapper-focused) {
      line-height: 30px;
      border-radius: 4px;
      //border-width: 0px 0px 1px 0px !important;
      box-shadow: none;
      background-color: #fff;
      &:hover,
      &:focus {
        //border-right-width: 0px !important;
        box-shadow: none;
      }
    }
    .code-img {
      position: absolute;
      right: 0;
      top: 4px;
      right: 4px;
      cursor: pointer;
      :deep(.ant-image-img) {
        width: auto;
        height: 100%;
      }
    }
    .btn {
      margin: 20px 0 10px 0;
      height: 40px;
      font-size: 16px;
      position: absolute;
      top: 470px;
      width: 304px;
      background: linear-gradient(0deg, #ff7b00 0%, #ffaa5b 100%);
      color: #ffffff;
    }
    .link {
      font-size: 14px;
      color: #b0bec5;
      text-decoration: underline;
      cursor: pointer;
      &:hover {
        color: @primary-color;
      }
    }
    :deep(.ant-input-clear-icon) {
      margin: 0;
    }
  }
}
.Prompt-font {
  //font-family: 'Arial Normal', 'Arial';
  font-weight: 400;
  font-style: normal;
  font-size: 10px;
  letter-spacing: normal;

  color: rgba(49, 61, 95, 0.6);
  font-family: PingFangSC-Semibold;
}
.tabTitle {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 24px;
  font-size: 24px;
  font-weight: 500;
  cursor: pointer;
}
.forgetBut {
  margin-bottom: 24px;
  font-size: 24px;
  font-weight: 500;
  cursor: pointer;
}
.codeLogin {
  margin-right: 40px;
  font-weight: 500;
}
.borderBut {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  //margin-top: 24px;
  padding-top: 24px;
}
.goBack {
  background: #ffece5;
  border-radius: 5px;
  color: #ff7b00;
  text-align: center;
  width: 94px;
  position: absolute;
  top: 19px;
  right: 19px;
  cursor: pointer;
}
.agreement {
  color: #999999;
  margin-top: 24px;
  font-size: 12px;
  position: absolute;
  top: 340px;
  width: 304px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.agreementActivity {
  margin-top: 0px;
  //top: 372px;
  top: 430px;
}
.butActivity {
  margin-top: 0px;
}
.titleActivity {
  background-image: url(../../assets/images/border.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0px 35px;
}
.login-logo {
  position: absolute;
  top: 229px;
  right: 678px;
}

@media screen and (min-width: 1366px) and (max-width: 1920px) {
  .login-logo {
    position: absolute;
    top: 229px;
    right: 778px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1366px) {
  .login-logo {
    position: absolute;
    top: 229px;
    right: 678px;
  }
}
.newAccount {
  color: #ff7b00;
}
.forgetPassword {
  color: #fa5151;
}
.downLoad {
  text-align: center;
  color: #ff7b00;
  border: 1px solid #ff8f1f;
  border-radius: 8px;
  padding: 10px 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 166px;
  //margin-left: 16px;
  margin-bottom: 20px;
  margin-left: 32%;
}
</style>
